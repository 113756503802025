.form {
  height: 100%;
  width: 100%;
}
::-webkit-input-placeholder {
  text-transform: uppercase;
  font-size: 0.8em;
}

:-moz-placeholder {
  text-transform: uppercase;
  font-size: 0.8em;
}

::-moz-placeholder {
  text-transform: uppercase;
  font-size: 0.8em;
}

:-ms-input-placeholder {
  text-transform: uppercase;
  font-size: 0.8em;
}
